import React, { useContext } from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";

// Components
import Divider from "../../../common/Divider";

// Context
import { MenuContext } from "../../context/MenuContext";

// Hooks
import useScreenDimension from "../../../../hooks/useScreenDimension";

// Interface
import { Icon } from ".";

// Theme
import { family } from "../../../../theme/fonts";
import { colors } from "../../../../theme/colors";

const BaseIcon = (props: Icon) => {
  const screen = useScreenDimension().screenDimension;
  const wideScreen = screen === "wide";

  const { icon, onPress, title, label } = props;
  const { selected, updateSelected } = useContext(MenuContext);

  const iconSelected = selected === label;
  const color = iconSelected ? colors.primary : colors.black;

  const clickHandler = () => {
    updateSelected(label);
    onPress();
  };

  if (wideScreen) {
    return (
      <TouchableOpacity onPress={clickHandler}>
        <MaterialCommunityIcons name={icon} size={36} color={color} />
      </TouchableOpacity>
    );
  }

  return (
    <TouchableOpacity style={styles.container} onPress={clickHandler}>
      <MaterialCommunityIcons name={icon} size={24} color={color} />
      <Divider height={5} />
      <Text style={[styles.title, { color: color }]}>{title}</Text>
    </TouchableOpacity>
  );
};

export default BaseIcon;

const styles = StyleSheet.create({
  wideContainer: { height: 50, width: 50 },
  container: {
    width: 50,
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontFamily: family.normal,
    fontSize: 12,
    color: colors.black,
  },
});
