import React, { useContext } from "react";
import { StyleSheet, Image, View } from "react-native";
import { useDispatch } from "react-redux";

// Components
import Divider from "../../common/Divider";
import BaseIcon from "./icons/BaseIcon";

// Context
import { MenuContext } from "../context/MenuContext";

// Store
import { signOutAction } from "../../../store/actions/userActions";

// Theme
import { colors } from "../../../theme/colors";

const WideMenu = () => {
  const { icons } = useContext(MenuContext);

  const dispatch = useDispatch();
  const signOutHandler = () => {
    dispatch(signOutAction());
  };

  const settingsHandler = () => {};

  const shownIcons = icons.filter((icon) => icon.label !== "menu");

  return (
    <View style={styles.leftBar}>
      <View style={styles.logoWrapper}>
        <Image
          style={styles.logo}
          source={require("../../../assets/logos/white_hand.png")}
        />
      </View>
      <View style={styles.content}>
        {shownIcons.map((item: any) => (
          <View key={item.gid}>
            <BaseIcon {...item} />
            <Divider height={20} />
          </View>
        ))}
      </View>
      <View style={styles.bottomContent}>
        <BaseIcon
          icon="cog-outline"
          onPress={settingsHandler}
          label="settings"
        />
        <Divider height={20} />
        <BaseIcon
          icon="account-outline"
          onPress={signOutHandler}
          label="profile"
        />
      </View>
    </View>
  );
};

export default WideMenu;

const styles = StyleSheet.create({
  leftBar: {
    position: "absolute",
    left: 0,
    width: 80,
    height: "100%",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.19,
    shadowRadius: 5.62,
    elevation: 6,
    padding: 12,
    justifyContent: "space-between",
    alignItems: "center",
  },
  logoWrapper: {
    width: 50,
    height: 50,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.primary,
  },
  logo: {
    width: 45,
    height: 45,
    resizeMode: "contain",
  },
  content: {
    paddingTop: 42,
    flex: 1,
  },
  bottomContent: {},
});
