import { combineReducers } from "@reduxjs/toolkit";

// Reducers
import { userReducer } from "./reducers/userReducer";

const rootReducer = combineReducers({
  user: userReducer,
});

export default rootReducer;
