import React from "react";
import { NavigationContainer } from "@react-navigation/native";

// Stacks
import StartupStack from "./stacks/StartupStack";

const AppNavigator = () => {
  return (
    <NavigationContainer>
      <StartupStack />
    </NavigationContainer>
  );
};

export default AppNavigator;
