import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

// Redux
import { useSelector } from "react-redux";

// Screens
import AuthHomeScreen from "../../screens/home/AuthHomeScreen";
import SplashScreen from "../../screens/home/SplashScreen";
import HomeScreen from "../../screens/home/HomeScreen";
import OfflineScreen from "../../screens/home/OfflineScreen";

const Stack = createStackNavigator();

function StartupStack() {
  const user = useSelector((state) => state?.user.user);
  console.log('user',user)
  const loggedIn = user?.token !== null && user?.token !== undefined;

  return (
    <Stack.Navigator
      initialRouteName="Splash"
      screenOptions={{ headerShown: false, title: "Gopick" }}
    >
      <Stack.Screen name="Splash" component={SplashScreen} />
      <Stack.Screen name="Offline" component={OfflineScreen} />
      <Stack.Screen
        name="Home"
        component={loggedIn ? HomeScreen : AuthHomeScreen}
      />
    </Stack.Navigator>
  );
}

export default StartupStack;
