export interface Permission {
  gid: number;
  key: string;
}

export const permissions: Permission[] = [
  { gid: 1, key: "manager_order_access" },
  { gid: 2, key: "manager_waiter_access" },
  { gid: 3, key: "manager_tpv_access" },
  { gid: 4, key: "manager_scanner_access" },
  { gid: 5, key: "manager_stock_access" },
  { gid: 6, key: "manager_return_order" },
  { gid: 7, key: "manager_kiosk_access" },
  { gid: 8, key: "manager_kdisplay_order" },
];
