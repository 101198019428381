import React from "react";
import { StyleSheet, Text } from "react-native";

// Theme
import { colors } from "../../theme/colors";
import { family } from "../../theme/fonts";

interface Props {
  error: string;
}
const ErrorMessage: React.FC<Props> = ({ error }) => (
  <Text style={styles.text}>{error}</Text>
);

export default ErrorMessage;

const styles = StyleSheet.create({
  text: {
    fontFamily: family.normal,
    color: colors.red,
    fontSize: 14,
  },
});
