import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";

//Theme
import { colors } from "../../theme/colors";
import { family } from "../../theme/fonts";

const TouchableText = ({ onPress, text = colors.black }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Text style={[styles.underline, styles.default]}>{text}</Text>
    </TouchableOpacity>
  );
};

export default TouchableText;

const styles = StyleSheet.create({
  default: {
    color: colors.black,
    fontFamily: family.normal,
    fontSize: 12,
  },
  underline: {
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
  },
});
