import React from "react";
import { StyleSheet, Image } from "react-native";

const MenuIcon = ({ icon, wideScreen }) => {
  const imageStyle = wideScreen ? styles.wideImage : styles.narrowImage;
  switch (icon) {
    case "tpv":
      return (
        <Image
          style={imageStyle}
          source={require("../../../../assets/images/tpv.png")}
        />
      );
    case "manager":
      return (
        <Image
          style={imageStyle}
          source={require("../../../../assets/images/manager.png")}
        />
      );
    case "waiter":
      return (
        <Image
          style={imageStyle}
          source={require("../../../../assets/images/waiter.png")}
        />
      );
    case "validator":
      return (
        <Image
          style={imageStyle}
          source={require("../../../../assets/images/validator.png")}
        />
      );
    case "kiosk":
      return (
        <Image
          style={imageStyle}
          source={require("../../../../assets/images/kiosk.jpeg")}
        />
      );
    case "kdisplay":
      return (
        <Image
          style={imageStyle}
          source={require("../../../../assets/images/kdisplay.png")}
        />
      );
    default:
      return (
        <Image
          style={imageStyle}
          source={require("../../../../assets/images/tpv.png")}
        />
      );
  }
};

export default MenuIcon;

const styles = StyleSheet.create({
  wideImage: {
    height: "60%",
    width: "100%",
    resizeMode: "cover",
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
  },
  narrowImage: {
    height: "100%",
    width: 120,
    resizeMode: "cover",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
});
