import React from "react";
import { StyleSheet, Text } from "react-native";

// Theme
import { version } from "../../../config";
import { colors } from "../../theme/colors";
import { family } from "../../theme/fonts";

const Version = () => <Text style={styles.version}>{version}</Text>;

export default Version;

const styles = StyleSheet.create({
  version: {
    fontFamily: family.light,
    color: colors.black,
    fontSize: 14,
  },
});
