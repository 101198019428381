import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import Menu from "../Menu";

// Hooks
import useScreenDimension from "../../hooks/useScreenDimension";

// Theme
import { colors } from "../../theme/colors";

const Screen = ({ children, menu = true }) => {
  const screen = useScreenDimension().screenDimension;
  const wideScreen = screen === "wide";

  const screenStyle = [
    styles.screen,
    menu && wideScreen && { paddingLeft: 80 },
  ];
  return (
    <View style={screenStyle}>
      {menu && <Menu />}
      {children}
    </View>
  );
};

export default Screen;

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: colors.white,
  },
});
