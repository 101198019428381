import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
} from "react-native";
import { AntDesign } from "@expo/vector-icons";

// Components
import Divider from "../../../../components/common/Divider";
import MenuIcon from "./MenuIcon";

// Hooks
import useScreenDimension from "../../../../hooks/useScreenDimension";

// Theme
import { colors } from "../../../../theme/colors";
import { family } from "../../../../theme/fonts";

interface Props {
  title: string;
  onPress?: void;
  subtitle?: string;
  icon?: any;
  label?: string;
}

const MenuOpcion = (props: Props) => {
  const { title, subtitle, onPress, label } = props;
  const screen = useScreenDimension().screenDimension;
  const wideScreen = screen === "wide";

  const containerStyle = wideScreen
    ? styles.wideContainer
    : styles.narrowContainer;

  if (wideScreen) {
    return (
      <TouchableOpacity style={[styles.containerBase, containerStyle]}>
        <View style={styles.content}>
          <MenuIcon icon={label} wideScreen={wideScreen} />
          <View style={styles.info}>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.subtitle}>{subtitle}</Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
  return (
    <TouchableOpacity
      style={[styles.containerBase, containerStyle]}
      onPress={() => {}}
    >
      <MenuIcon icon={label} wideScreen={wideScreen} />
      <Divider width={10} />
      <View style={styles.column}>
        <Text style={[styles.title, { fontSize: 18 }]}>{title}</Text>
        <Text style={[styles.subtitle, { fontSize: 14 }]}>{subtitle}</Text>
      </View>
      <View style={styles.arrowContainer}>
        <AntDesign name="right" size={24} color="black" />
      </View>
    </TouchableOpacity>
  );
};

export default MenuOpcion;

const styles = StyleSheet.create({
  containerBase: {
    borderRadius: 20,
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.19,
    shadowRadius: 5.62,
    elevation: 6,
    maxHeight: 300,
  },
  content: {
    flexDirection: "column",
    flex: 1,
    borderRadius: 20,
  },
  info: {
    paddingHorizontal: 20,
    height: "40%",
    width: "100%",
    flexDirection: "column",
    backgroundColor: colors.white,
    borderRadius: 20,
    padding: 10,
  },
  column: {
    paddingHorizontal: 20,
    flex: 1,
    flexDirection: "column",
    backgroundColor: colors.white,
    padding: 10,
  },

  wideContainer: {
    flexDirection: "row",
    width: "48%",
    marginBottom: 10,
  },
  narrowContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  arrowContainer: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 10,
  },
  title: {
    fontFamily: family.bold,
    color: colors.black,
    fontSize: 24,
    flex: 1,
  },
  subtitle: {
    fontFamily: family.light,
    color: colors.black,
    fontSize: 18,
    flex: 1,
    lineHeight: Platform.OS === "web" ? 20 : null,
    opacity: 0.8,
  },
});
