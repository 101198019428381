import React, { SetStateAction, useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { useDispatch } from "react-redux";

// Api
import { signIn } from "../../../../api/auth/signIn";

// Components
import Button from "../../../../components/common/Button";
import Divider from "../../../../components/common/Divider";
import Input from "../../../../components/common/Input";
import TouchableText from "../../../../components/common/TouchableText";
import Version from "../../../../components/common/Version";
import ErrorMessage from "../../../../components/common/ErrorMessage";
import Title from "../components/Title";

// Store
import { signInAction } from "../../../../store/actions/userActions";
import { getUserData } from "../../../../api/auth/getUserData";

interface LoginProps {
  setSection: React.Dispatch<SetStateAction<string>>;
}

const Login: React.FC<LoginProps> = ({ setSection }) => {
  const [user, setUser] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>();
  const [status, setStatus] = useState<string>("idle");
  const buttonActive = user !== "" && password !== "";

  const dispatch = useDispatch();

  useEffect(() => {
    setError(null);
  }, [user, password]);

  async function loginHandler() {
    setStatus("loading");
    const response = await signIn(user, password);
    const { data, error, status } = response;
    if (status === "success") {
      const userResponse = await getUserData(data.accessToken);
      const {
        data: userData,
        error: userError,
        status: userStatus,
      } = userResponse;
      if (userStatus === "success") {
        setStatus("success");
        dispatch(signInAction({ ...userData, token: data.accessToken }));
      } else {
        setStatus("error");
        setError(userError);
      }
    } else {
      setStatus("error");
      setError(error);
    }
  }

  const forgotPasswordHandler = () => {
    setSection("forgotPassword");
  };

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Title title="Iniciar sesión" />
        <Divider height={24} />
        <Input value={user} onChange={setUser} placeholder="Usuario" />
        <Divider height={12} />
        <Input
          value={password}
          secure
          onChange={setPassword}
          placeholder="Contraseña"
        />
        <Divider height={12} />
        <Button
          text={"Iniciar sesión"}
          onPress={loginHandler}
          active={buttonActive}
          loading={status === "loading"}
        />
        <Divider height={12} />
        {error && <ErrorMessage error={error} />}
        {error && <Divider height={12} />}
        <TouchableText
          onPress={forgotPasswordHandler}
          text="¿Olvidaste la contraseña?"
        />
      </View>
      <Version />
    </View>
  );
};

export default Login;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    width: 350,
    padding: 18,
  },
});
