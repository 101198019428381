import { permissions } from "../types/Permission";
import User from "../types/User";

const parsePermissions = (keys: string[]): number[] => {
  return keys
    .map((key) => permissions.find((permission) => permission.key === key)?.gid)
    .filter((gid) => gid !== null) as number[];
};

export const mapUser = (data: any): User => {
  const newUser = {
    email: data?.email || "",
    gid: data?.gid || null,
    language: data?.language || "es",
    permissions: parsePermissions(data?.permissions || []),
    pointSales: data?.pointSales || [],
    roles: data?.roles || "",
    settings: {
      alarm: data?.setting?.alarm || [],
      location: data?.setting?.location || "",
      print: data?.setting?.print || [],
      tpvPrint: data?.setting?.tpv_print || false,
      waiterPrint: data?.setting?.waiter_print || false,
    },
    shops: data?.shops || [],
    timezone: data?.timezone || "",
    token: data?.token || null,
  };

  return newUser;
};
