export interface Icon {
  icon: any;
  onPress: any;
  title?: string;
  label?: string;
  gid?: number;
  permission?: number;
}

export interface Props {
  navigation: any;
}

export const getIcons = (props: Props) => {
  const icons: Icon[] = [
    {
      gid: 1,
      label: "orders",
      title: "Pedidos",
      icon: "receipt",
      onPress: () => {},
      permission: 1,
    },
    {
      gid: 2,
      label: "shops",
      title: "Tiendas",
      icon: "store",
      onPress: () => {},
      permission: null,
    },
    {
      gid: 3,
      label: "stock",
      title: "Stock",
      icon: "package-variant-closed",
      onPress: () => {},
      permission: 5,
    },
    {
      gid: 4,
      label: "validator",
      title: "Escaner",
      icon: "cube-scan",
      onPress: () => {},
      permission: 4,
    },
    {
      gid: 5,
      label: "menu",
      title: "Menú",
      icon: "menu",
      onPress: () => {},
      permission: null,
    },
  ];
  return icons;
};
