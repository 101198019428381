import React, { FC } from "react";
import { StyleSheet, Text, TextInput, TextInputProps } from "react-native";

// Theme
import { colors } from "../../theme/colors";
import { family } from "../../theme/fonts";

interface InputProps extends TextInputProps {
  value: string;
  onChange: any;
  placeholder?: string;
  secure?: boolean;
}

const Input: FC<InputProps> = ({
  value,
  onChange,
  placeholder,
  secure = false,
  ...rest
}) => {
  return (
    <TextInput
      style={styles.input}
      value={value}
      onChangeText={onChange}
      placeholder={placeholder}
      {...rest}
      placeholderTextColor={colors.grey}
      secureTextEntry={secure}
    />
  );
};

export default Input;

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderColor: colors.grey,
    borderRadius: 8,
    fontSize: 20,
    fontFamily: family.normal,
    paddingVertical: 8,
    paddingHorizontal: 12,
    color: colors.black,
  },
});
