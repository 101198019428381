import React, { useContext } from "react";
import { StyleSheet, View } from "react-native";

// Components
import BaseIcon from "./icons/BaseIcon";

// Context
import { MenuContext } from "../context/MenuContext";

// Theme
import { colors } from "../../../theme/colors";

const NarrowScreen = () => {
  const { icons } = useContext(MenuContext);

  return (
    <View style={styles.bottomBar}>
      <View style={styles.content}>
        {icons.map((item) => (
          <BaseIcon key={item.gid} {...item} />
        ))}
      </View>
    </View>
  );
};

export default NarrowScreen;

const styles = StyleSheet.create({
  bottomBar: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: 70,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.19,
    shadowRadius: 5.62,
    elevation: 6,
    padding: 12,
    justifyContent: "center",
    alignItems: "center",
    borderTopWidth: 2,
    borderTopColor: colors.lightGrey,
    zIndex: 10,
  },
  content: {
    justifyContent: "space-evenly",
    flexDirection: "row",
    maxWidth: 400,
    width: "100%",
  },
});
