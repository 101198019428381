export interface MenuService {
  gid: number;
  title: string;
  subtitle?: string;
  label?: string;
  onPress?: any;
  permission: number;
}

export const menuServices: MenuService[] = [
  {
    gid: 1,
    title: "TPV",
    subtitle: "Sistema para atender pedidos",
    label: "tpv",
    permission: 3,
  },
  {
    gid: 2,
    title: "Gestor de pedidos",
    subtitle: "Sistema para atender y gestionar los pedidos",
    label: "manager",
    permission: 1,
  },
  {
    gid: 3,
    title: "Camarero",
    subtitle: "Sistema para atender a los clientes en mesa",
    label: "waiter",
    permission: 2,
  },
  {
    gid: 4,
    title: "Validador",
    subtitle: "Sistema de validación de pedidos",
    label: "validator",
    permission: 4,
  },
  {
    gid: 5,
    title: "Kiosko",
    subtitle: "Autoservicio para usuarios",
    label: "kiosk",
    permission: 7,
  },
  {
    gid: 6,
    title: "Comandero",
    subtitle: "Sistema de visualización de pedidos en tiempo real",
    label: "kdisplay",
    permission: 8,
  },
];
