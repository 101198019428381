export const environment = "dev"; //dev || prod
export const app = "manager"; // manager || dataphone

export const baseVersion = "0.1.0";
const date = "";

export const version =
  environment === "dev"
    ? `v2-${baseVersion}-dev-${date}`
    : `v2-${baseVersion}${date}`;
