import React from "react";
import { StyleSheet } from "react-native";

// Components
import NarrowMenu from "./components/NarrowMenu";
import WideMenu from "./components/WideMenu";

// Context
import { MenuProvider } from "./context/MenuContext";

// Theme
import useScreenDimension from "../../hooks/useScreenDimension";

const Menu = ({ navigation }) => {
  const screen = useScreenDimension().screenDimension;
  const wideScreen = screen === "wide";

  return (
    <MenuProvider navigation={navigation}>
      {wideScreen ? <WideMenu /> : <NarrowMenu />}
    </MenuProvider>
  );
};

export default Menu;

const styles = StyleSheet.create({});
