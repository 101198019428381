import Settings from "./Settings";

type User = {
  email: string;
  gid: number;
  language: string;
  permissions: number[];
  pointSales: number[];
  roles: string;
  settings?: Settings;
  shops: number[];
  timezone: string;
  token: string;
};

export const EMPTY_USER = {
  email: "",
  gid: null,
  language: "es",
  permissions: [],
  pointSales: [],
  roles: "",
  settings: {
    alarm: [],
    location: "",
    print: [],
    tpvPrint: false,
    waiterPrint: false,
  },
  shops: [],
  timezone: "",
  token: null,
};

export default User;
