import { post } from "../post";
import { BASE_URL } from "../urls";

interface SignInResponse {
  status: "success" | "error";
  data?: any;
  error?: string;
}

export async function signIn(
  email: string,
  password: string
): Promise<SignInResponse> {
  try {
    const url = `${BASE_URL}auth/sign-in`;
    const response = await post(url, { email, password });

    const { status, data, error } = response;

    if (status === "success" && data) {
      return { status: "success", data: data };
    } else {
      console.error("[error] signin", error);
      return { status: "error", error: error };
    }
  } catch (error) {
    console.error("[error] signIn", error);
    return { status: "error", error: error.message };
  }
}
