import { createReducer } from "@reduxjs/toolkit";

// Actions
import { signInAction, signOutAction } from "../actions/userActions";
import { mapUser } from "../services/userService";

// Types
import User, { EMPTY_USER } from "../types/User";

interface UserState {
  user: User;
}

const initialState: UserState = {
  user: EMPTY_USER,
};

export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(signInAction, (state, action) => {
      const apiUserData = action.payload;
      const newUser: User = mapUser(apiUserData);
      state.user = newUser;
    })
    .addCase(signOutAction, (state) => {
      state.user = EMPTY_USER;
    });
});
