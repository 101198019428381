import React from "react";
import { StyleSheet, View, ImageBackground, Image } from "react-native";

// Theme
import { colors } from "../../../../theme/colors";

const HeroImage = () => {
  return (
    <ImageBackground
      style={styles.container}
      source={require("../../../../assets/images/bar.webp")}
    >
      <View style={styles.opacity} />
      <Image
        style={styles.logo}
        source={require("../../../../assets/logos/white_logo.svg")}
      />
    </ImageBackground>
  );
};

export default HeroImage;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    resizeMode: "cover",
  },
  opacity: {
    flex: 1,
    backgroundColor: colors.primary,
    opacity: 0.6,
  },
  logo: {
    position: "absolute",
    bottom: 0,
    right: 0,
    opacity: 0.6,
    width: 150,
    height: 150,
  },
});
