import React, { FC } from "react";
import { View, DimensionValue } from "react-native";

interface DividerProps {
  height?: DimensionValue;
  width?: DimensionValue;
  color?: string;
}

const Divider: FC<DividerProps> = ({
  height = 1,
  width = 1,
  color,
  ...rest
}) => {
  return (
    <View
      style={{
        height: height,
        width: width,
        borderBottomWidth: color ? 1 : 0,
        borderColor: color,
        ...rest,
      }}
    />
  );
};

export default Divider;
