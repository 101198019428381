import React, { FC, Dispatch, SetStateAction, useState } from "react";
import { StyleSheet, View, Text } from "react-native";

// Components
import Button from "../../../../components/common/Button";
import Divider from "../../../../components/common/Divider";
import Input from "../../../../components/common/Input";
import TouchableText from "../../../../components/common/TouchableText";
import Version from "../../../../components/common/Version";
import Title from "../components/Title";

// Theme
import { colors } from "../../../../theme/colors";
import { family } from "../../../../theme/fonts";

interface LoginProps {
  setSection: Dispatch<SetStateAction<string>>;
}

const ForgotPassword: FC<LoginProps> = ({ setSection }) => {
  const [user, setUser] = useState<string>("");
  const [error, setError] = useState<string>("");

  const loginHandler = () => {};
  const forgotPasswordHandler = () => {
    setSection("login");
  };

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Title title="¿Olvidaste la contraseña?" />
        <Divider height={12} />
        <Text style={styles.subtitle}>
          No te preocupes. Introduce tu usuario y te enviaremos un enlace para
          recuperar tu contraseña
        </Text>
        <Divider height={12} />
        <Input value={user} onChange={setUser} />
        <Divider height={12} />
        <Button text={"Recuperar contraseña"} onPress={loginHandler} />
        <Divider height={12} />
        <TouchableText onPress={forgotPasswordHandler} text="Inicia sesión" />
      </View>
      <Version />
    </View>
  );
};

export default ForgotPassword;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    width: 350,
    padding: 18,
  },
  subtitle: {
    fontFamily: family.light,
    fontSize: 14,
    color: colors.black,
    textAlign: "left",
  },
});
