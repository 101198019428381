import React, { useEffect } from "react";
import { StyleSheet, Image, ActivityIndicator, View } from "react-native";
import { fetch } from "@react-native-community/netinfo";

// Components
import Divider from "../../../components/common/Divider";
import Version from "../../../components/common/Version";
import Screen from "../../../components/Screen";

// Theme
import { colors } from "../../../theme/colors";

const SplashScreen = ({ navigation }) => {
  const getStartupData = async () => {
    setTimeout(() => {
      fetch().then((state) => {
        if (state.isConnected) {
          navigation.navigate("Home");
        } else {
          navigation.navigate("Offline");
        }
      });
    }, 10);
  };

  useEffect(() => {
    getStartupData();
  }, []);

  return (
    <Screen menu={false}>
      <View style={styles.container}>
        <Image
          style={styles.image}
          source={require("../../../assets/logos/logo.svg")}
        />
        <Divider height={22} />
        <ActivityIndicator size="small" color={colors.primary} />
        <Divider height={22} />
        <Version />
      </View>
    </Screen>
  );
};

export default SplashScreen;

const styles = StyleSheet.create({
  image: {
    width: 200,
    height: 200,
    resizeMode: "contain",
  },
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
