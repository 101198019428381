import React from "react";
import { StyleSheet, Text } from "react-native";

// Theme
import { colors } from "../../../../theme/colors";
import { family } from "../../../../theme/fonts";

const Title = ({ title }) => <Text style={styles.title}>{title}</Text>;

export default Title;

const styles = StyleSheet.create({
  title: {
    fontFamily: family.bold,
    color: colors.black,
    fontSize: 24,
  },
});
