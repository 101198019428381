import React from "react";
import { StyleSheet, Text } from "react-native";

// Components
import Screen from "../../../components/Screen";

const OfflineScreen = () => {
  return (
    <Screen menu={false}>
      <Text>Offline Screen</Text>
    </Screen>
  );
};

export default OfflineScreen;

const styles = StyleSheet.create({});
