import React from "react";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

// Components
import Screen from "../../../components/Screen";
import MenuOpcion from "./components/MenuOption";

// Hooks
import useScreenDimension from "../../../hooks/useScreenDimension";

// Theme
import { LAPTOP } from "../../../theme/breakPoints";

// Services
import { MenuService, menuServices } from "./services/services";

const HomeScreen = () => {
  const permissions = useSelector((state: any) => state?.user.user.permissions);
  const screen = useScreenDimension().screenDimension;
  const wideScreen = screen === "wide";

  const containerStyle = wideScreen
    ? styles.wideContainer
    : styles.narrowContainer;

  const shownOptions = menuServices.filter(
    (service) =>
      permissions?.includes(service.permission) || service.permission === null
  );

  return (
    <Screen>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={containerStyle}>
            {shownOptions.map((item: MenuService) => (
              <MenuOpcion
                key={item.gid}
                label={item.label}
                title={item.title}
                subtitle={item.subtitle}
              />
            ))}
          </View>
        </View>
      </View>
    </Screen>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    width: "100%",
    height: "100%",
    maxWidth: LAPTOP,
    maxHeight: 600,
  },
  wideContainer: {
    flex: 1,
    gap: 20,
    padding: 36,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  narrowContainer: {
    padding: 36,
    flex: 1,
    display: "flex",
    gap: 20,
  },
});
