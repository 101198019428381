export const colors = {
  primary: "#F75353",
  lightPrimary: "#FFB9B9",
  secondary: "#009682",
  black: "#424242",
  white: "#fffffb",
  red: "#C61414",
  grey: "#C9C9C9",
  lightGrey: "#F3F5F6",
};
