import React, { FC } from "react";
import {
  StyleSheet,
  Text,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";

// Theme
import { colors } from "../../theme/colors";
import { family } from "../../theme/fonts";

interface ButtonProps {
  text: string;
  onPress: any;
  active?: boolean;
  loading?: boolean;
}

const Button: FC<ButtonProps> = ({
  onPress,
  text,
  active = true,
  loading = false,
}) => {
  const buttonStyle = [styles.button, !active && styles.buttonInactive];

  function buttonHandler() {
    if (active) {
      onPress();
    }
  }
  return (
    <TouchableOpacity style={buttonStyle} onPress={buttonHandler}>
      {loading ? (
        <ActivityIndicator color={colors.white} size="small" />
      ) : (
        <Text style={styles.text}>{text}</Text>
      )}
    </TouchableOpacity>
  );
};

export default Button;

const styles = StyleSheet.create({
  button: {
    borderRadius: 8,
    fontSize: 20,
    fontFamily: family.normal,
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: colors.primary,
  },
  text: {
    fontFamily: family.normal,
    fontSize: 20,
    textAlign: "center",
    color: colors.white,
  },
  buttonInactive: {
    backgroundColor: colors.lightPrimary,
  },
});
