import React, { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";

// Components
import { getIcons, Icon } from "../components/icons";

const MenuContext = createContext(null);

const MenuProvider = ({ navigation, children }) => {
  const permissions = useSelector((state: any) => state?.user.user.permissions);
  const [selected, setSelected] = useState<string>("");
  const [icons, setIcons] = useState<Icon[]>([]);

  useEffect(() => {
    const icons = getIcons(navigation);
    const shownIcons = icons.filter(
      (icon) =>
        permissions?.includes(icon.permission) || icon.permission === null
    );
    setIcons(shownIcons);
  }, []);

  const updateSelected = (value: string) => {
    setSelected(value);
  };

  return (
    <MenuContext.Provider value={{ selected, icons, updateSelected }}>
      {children}
    </MenuContext.Provider>
  );
};

export { MenuProvider, MenuContext };
