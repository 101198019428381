import { baseVersion } from "../../config";

export const post = async (url: string, body: any) => {
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
        "X-Client-Version": baseVersion,
      },
      body: JSON.stringify(body),
      method: "POST",
    });
    const resData = await response.json();
    const { status, data, message } = resData;

    if (status === "success") {
      return { status: "success", data };
    } else {
      return { status: "fail", error: message };
    }
  } catch (error) {
    console.log("[error] posting '", url, "': ", error);
    return { status: "fail", error: error.message };
  }
};
