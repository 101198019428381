import React, { useState } from "react";
import { View, StyleSheet } from "react-native";

// Components
import Screen from "../../../components/Screen";
import HeroImage from "./components/HeroImage";

// Hooks
import useScreenDimension from "../../../hooks/useScreenDimension";

// Sections
import ForgotPassword from "./sections/ForgotPassword";
import Login from "./sections/Login";

const AuthHomeScreen = () => {
  const [section, setSection] = useState("login");
  const screen = useScreenDimension().screenDimension;
  const wideScreen = screen === "wide";

  const getSection = () => {
    if (section === "login") {
      return <Login setSection={setSection} />;
    }
    if (section === "forgotPassword") {
      return <ForgotPassword setSection={setSection} />;
    }
  };

  return (
    <Screen menu={false}>
      <View style={styles.container}>
        {getSection()}
        {wideScreen && <HeroImage />}
      </View>
    </Screen>
  );
};

export default AuthHomeScreen;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flex: 1,
    borderWidth: 1,
  },
});
